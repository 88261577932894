import React from 'react'
import Helmet from 'react-helmet'
import { Player, ControlBar } from 'video-react'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import sunocoAboutUs from '../assets/images/sunoco-about-us.png'
import reactLogo from '../assets/images/react-logo.png'
import wordpressLogo from '../assets/images/wordpress-logo.png'
import scssLogo from '../assets/images/scss-logo.png'
import expressLogo from '../assets/images/express-logo.png'
import reduxLogo from '../assets/images/redux-logo.png'
import ansibleLogo from '../assets/images/ansible-logo.png'
import sunocoLogo from '../assets/images/sunoco-green.png'
import sunocoVideo from '../assets/videos/sunoco-slideshow.mp4'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | Sunoco"
      meta={[
        {
          name: 'description',
          content: 'Headless Wordpress React Site',
        },
        {
          name: 'keywords',
          content: 'sunoco, wordpress, react, headless',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="Sunoco"
      description="Headless Wordpress + React Site"
      to="https://www.sunoco.com/"
      backgroundImage={sunocoLogo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            Sunoco wanted a complete redesign of their company's website so we
            chose to use React and Redux for the front-end and Wordpress as a
            headless CMS. We used Express JS to serve the website and to
            implement server-side rendering. Ansible was used to provision and
            deploy the website to an EC2 instance in AWS.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={reactLogo}
              alt="React logo"
              className="logo-soup-individual"
            />
            <img
              src={wordpressLogo}
              alt="Wordpress logo"
              className="logo-soup-individual"
            />
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={expressLogo}
              alt="Express logo"
              className="logo-soup-individual"
            />
            <img
              src={reduxLogo}
              alt="Redux logo"
              className="logo-soup-individual"
            />
            <img
              src={ansibleLogo}
              alt="Ansible logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This Headless Wordpress/React site was made using the following
                front-end technologies, served with Express JS, and provisioned
                using Ansible.
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://reactjs.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      React
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wordpress.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wordpress
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://expressjs.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Express.js
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://redux.js.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Redux
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.ansible.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ansible
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <a
            href="https://www.sunoco.com/"
            className="image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Player autoPlay muted loop>
              <source src={sunocoVideo} />
              <ControlBar disableCompletely />
            </Player>
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                I was responsible for the entire site except for the store
                locator page. This included designing/styling the rest of the
                site, developing all of the server-side technologies, and
                setting up Wordpress to act as a headless CMS. We used the{' '}
                <a
                  href="https://www.advancedcustomfields.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ACF
                </a>{' '}
                Wordpress plugin to setup the CMS so that it could be used by a
                variety of different users at Sunoco. The front-end was designed
                using SCSS according to designs made in Zeplin.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={sunocoAboutUs} alt="Sunoco About Us Page" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Additional Information</h3>
              </header>
              <p>
                The majority of the work for this site was implementing server
                side rendering with Express JS. This site was the first headless
                Wordpress/React website we made at T3 so the entire server was
                coded from scratch. T3 took the server-side work I did on this
                project and used it as a boilerplate for every other headless
                project at our company.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
